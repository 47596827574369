* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Ubuntu', sans-serif;
}

/* ubuntu-regular - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/ubuntu-v20-latin/ubuntu-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/ubuntu-v20-latin/ubuntu-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/ubuntu-v20-latin/ubuntu-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/ubuntu-v20-latin/ubuntu-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/ubuntu-v20-latin/ubuntu-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/ubuntu-v20-latin/ubuntu-v20-latin-regular.svg#Ubuntu') format('svg'); /* Legacy iOS */
}